import React, { useState, useEffect } from "react";
import "./Minecraft.css";
import "./public.css";
import 'bootstrap/dist/css/bootstrap.css';
import Flag from 'react-world-flags';
//https://en.wikipedia.org/wiki/ISO_3166-1_numeric

export const Minecraft = () => {
  const [packageDetails, setPackageDetails] = useState([]);
  const [locationOption, setlocationOption] = useState([]);
  const [locations, setlocations] = useState([]);
  const [affiliateID, setAffiliateID] = useState(null);
  const [jsonFile, setJsonFile] = useState("/budget minecraft.json");
  const [activePackage, setActivePackage] = useState('Budget');
  const [activeLocation, setActiveLocation] = useState(null);

  useEffect(() => {
    fetch(jsonFile)
      .then(res => res.json())
      .then(data => {
        setPackageDetails(data.packages);
        setlocationOption(data.locationOption);
        setlocations(data.locations);

        if (data.locations && data.locations.length > 0) {
          setActiveLocation(data.locations[0].id);
        }
      })
      .catch(error => console.error(error));

    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const affiliateIDFromCookie = getCookie("partnerAffiliateID");
    if (affiliateIDFromCookie) {
      setAffiliateID(affiliateIDFromCookie);
    }
  }, [jsonFile]);

  const handlePackageSelect = (packageType) => {
    if (packageType === 'Budget') {
      setJsonFile("/budget minecraft.json");
      setActivePackage('Budget');
    } else if (packageType === 'Premium') {
      setJsonFile("/minecraft.json");
      setActivePackage('Premium');
    }
  };

  const handleLocationSelect = (locationId) => {
    setActiveLocation(locationId);
  };

  return (
    <div className="info">
      <br />
      <h1>Minecraft Java</h1>
      
      <div className="d-flex row justify-content-center mb-3">
        <div className="thigsbox">
          <h2 style={{ color: "#ffffff" }}>Budget Minecraft</h2>
          <p>Intel Xeon V4</p>
          <p>DDR4 Ram</p>
          <p>SSD Storage</p>
          <button onClick={() => handlePackageSelect('Budget')} className={`btn ${activePackage === 'Budget' ? 'btn-primary' : 'btn-secondary'}`}>{activePackage === 'Budget' ? 'Selected' : 'Select'}</button>
        </div>
        <div className="thigsbox">
          <h2 style={{ color: "#ffffff" }}>Premium Minecraft</h2>
          <p>AMD AM5 Cpus</p>
          <p>DDR5 Ram</p>
          <p>NVMe Storage</p>
          <button onClick={() => handlePackageSelect('Premium')} className={`btn ${activePackage === 'Premium' ? 'btn-primary' : 'btn-secondary'}`}>{activePackage === 'Premium' ? 'Selected' : 'Select'}</button>
        </div>
      </div>
      
      <h6>Server Location</h6>
      <div className="d-flex row justify-content-center">
        {locations.map(location => (
          <div key={location.id} className={`locationbox ${location.id}`}>
            <h2 style={{ color: "#ffffff" }}>
              <Flag code={location.countryCode} style={{ width: "20px", marginRight: "10px" }} />
              {location.name}
            </h2>
            <button 
              onClick={() => handleLocationSelect(location.id)} 
              className={`btn ${activeLocation === location.id ? 'btn-primary' : 'btn-secondary'}`}
            >
              {activeLocation === location.id ? 'Selected' : 'Select'}
            </button>
          </div>
        ))}
      </div>

      <h6>{activePackage} Minecraft Server Packages</h6>
      <div className="game-cont">
        <div className="d-flex row justify-content-center">
          {packageDetails.map(packageDetail => (
            <div key={packageDetail.id} className={`packagebox ${packageDetail.id}`}>
              <h2 style={{ color: "#ffffff" }}>{packageDetail.name}</h2>
              <p>{packageDetail.memory / 1024} GB RAM</p>
              <p>{packageDetail.cpu / 100} {packageDetail.cpu / 100 === 1 ? "vCore" : "vCores"}</p>
              <p>Pterodactyl Panel</p>
              <p>Starting At €{Number(packageDetail.price).toFixed(2).replace(/\.0+$/, "")}</p>
              <a href={`${packageDetail.link}?options[${locationOption}]=${activeLocation}${affiliateID ? `&ref=${encodeURIComponent(affiliateID)}` : ""}`}>
                Order Now
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
