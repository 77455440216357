import React, { useState, useEffect }from "react";
import "./Unturned.css";
import "./public.css";
import 'bootstrap/dist/css/bootstrap.css';
import Flag from 'react-world-flags';

export const Unturned = () => {
  const [packageDetails, setPackageDetails] = useState([]);
  const [affiliateID, setAffiliateID] = useState(null);
  const [locationOption, setlocationOption] = useState([]);
  const [locations, setlocations] = useState([]);
  const [activeLocation, setActiveLocation] = useState(null);
  useEffect(() => {
    fetch('/unturned.json')
      .then(res => res.json())
      .then(data => {
        setPackageDetails(data.packages);
        setlocationOption(data.locationOption);
        setlocations(data.locations);

        if (data.locations && data.locations.length > 0) {
          setActiveLocation(data.locations[0].id);
        }
      })
      .catch(error => console.error(error));

    const getCookie = (name) => {
      const value = `; ${document.cookie}`;
      const parts = value.split(`; ${name}=`);
      if (parts.length === 2) return parts.pop().split(';').shift();
    };

    const affiliateIDFromCookie = getCookie("partnerAffiliateID");
    if (affiliateIDFromCookie) {
      setAffiliateID(affiliateIDFromCookie);
    }
  }, []);
  const handleLocationSelect = (locationId) => {
    setActiveLocation(locationId);
  };
  return (
    <div className="info">
      <br></br>
      <h1>Unturned</h1>
      <h6>Server Location</h6>
      <div className="d-flex row justify-content-center">
        {locations.map(location => (
          <div key={location.id} className={`locationbox ${location.id}`}>
            <h2 style={{ color: "#ffffff" }}>
              <Flag code={location.countryCode} style={{ width: "20px", marginRight: "10px" }} />
              {location.name}
            </h2>
            <button 
              onClick={() => handleLocationSelect(location.id)} 
              className={`btn ${activeLocation === location.id ? 'btn-primary' : 'btn-secondary'}`}
            >
              {activeLocation === location.id ? 'Selected' : 'Select'}
            </button>
          </div>
        ))}
      </div>
      <div className="game-cont">
        <div className="d-flex row justify-content-center">
          {packageDetails.map(packageDetail => (
            <div key={packageDetail.id} className={`packagebox ${packageDetail.id}`}>
              <h2 style={{ color: "#ffffff" }}>{packageDetail.name}</h2>
              <h2>{packageDetail.name}</h2>
              <p>{packageDetail.slots} Player Slots</p>
              <p>{packageDetail.memory/1024} GB RAM</p>
              <p>{packageDetail.cpu/100} {packageDetail.cpu/100 === 1 ? 'vCore' : 'vCores'}</p>
              <p>Pterodactyl Panel</p>
              <a href={`${packageDetail.link}?options[${locationOption}]=${activeLocation}${affiliateID ? `&ref=${encodeURIComponent(affiliateID)}` : ""}`}>
                Order Now
              </a>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};